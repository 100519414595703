<template>
  <div class="main">
    <heand :tagnum="tagnum"></heand>
    <div class="enterprise clearfix">
      <div class="left">
        <h1 class="seladd_tit">
          <img src="../../assets/selectionadd/dizhiicon.png" alt />智能选址建议
        </h1>
        <dl class="seladd_dl">
          <dt>
            <img src="../../assets/selectionadd/NO1.png" alt />
          </dt>
          <dd>
            <p>{{ data[0].name }}</p>
          </dd>
          <ul>
            <li>园区负责人姓名：{{ data[0].charge }}</li>
            <li>负责人联系电话：{{ data[0].phone }}</li>
          </ul>
        </dl>

        <dl class="seladd_dl" v-if="!!data[1]">
          <dt>
            <img src="../../assets/selectionadd/NO2.png" alt />
          </dt>
          <dd>
            <p>{{ data[1].name }}</p>
          </dd>

          <ul>
            <li>园区负责人姓名：{{ data[1].charge }}</li>
            <li>负责人联系电话：{{ data[1].phone }}</li>
          </ul>
        </dl>

        <dl class="seladd_dl" v-if="!!data[2]">
          <dt>
            <img src="../../assets/selectionadd/NO3.png" alt />
          </dt>
          <dd>
            <p>{{ data[2].name }}</p>
          </dd>

          <ul>
            <li>园区负责人姓名：{{ data[2].charge }}</li>
            <li>负责人联系电话：{{ data[2].phone }}</li>
          </ul>
        </dl>
      </div>
      <div class="right">
        <baidu-map
          v-if="showmap"
          class="bm-view"
          :center="center"
          :double-click-zoom="double"
          :zoom="zoom"
        >
          <bm-marker :position="center" :dragging="false"></bm-marker>
        </baidu-map>
      </div>
    </div>
  </div>
</template>

<script>
import { smart, smartmap } from "@/api/index";
export default {
  data() {
    return {
      tagnum: 4,
      data: [],
      center: { lng: 112.549, lat: 37.957 }, //经纬度
      zoom: 15, //地图展示级别
      showmap: true,
      district: [],
      alilte: [],
      double: false,
    };
  },
  created() {
    console.log(this.$route.query)
    this.sendsmart(this.$route.query);
    
  },
  methods: {
    async sendsmart(data) {
      const res = await smart(data);
      console.log(res,"成功")
      this.data = res;
      //   this.center.lng = res.jwd.lng;
      //   this.center.lat = res.jwd.lat;
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  background: rgb(243, 243, 243);
}
.enterprise {
  padding: 10px 30px;
}
.left {
  float: left;
  background: #fff;
  width: 40%;
  height: 87vh;
}
.right {
  background: #fff;
  width: calc(100% - 40% - 50px);
  height: 83vh;
  float: right;
  padding: 20px;
}
.seladd_tit {
  font-size: 16px;
  background: url("../../assets/selectionadd/titles.png") no-repeat center;
  height: 83px;
  line-height: 70px;
  text-align: center;
  color: #fff;
  font-weight: normal;
  margin-top: 35px;
}
.seladd_tit img {
  margin-right: 5px;
  vertical-align: middle;
}
.seladd_dl {
  margin: 15px 40px;
  min-height: 150px;
  padding: 20px 5px 0 10px;
  background: #ffffff;
  border: 1px solid #feb683;
  border-radius: 4px;
  overflow: hidden;
  zoom: 1;
}

.seladd_dl dt {
  width: 51px;
  float: left;
}
.seladd_dl dd {
  float: right;
  width: calc(100% - 61px);
}
.seladd_dl dd p {
  text-align: left;
  color: #666;
  font-size: 18px;
  height: 52px;
  line-height: 26px;
  font-weight: 700;
}
ul {
  width: 100%;
  padding: 0 10px;
  margin: 0 auto;
  margin-top: 90px;
  overflow: hidden;
  zoom: 1;
}
li {
  float: left;
  width: 40%;
  line-height: 26px;
  list-style: none;
  font-size: 14px;
  color: #666;
  text-align: left;
  background: url("../../assets/selectionadd/tx_icon.png") no-repeat left top;
  padding-left: 27px;
}
li:last-child {
  width: 60%;
  background: url("../../assets/selectionadd/tel_icon.png") no-repeat left top;
}
.bm-view {
  width: 100%;
  height: 100%;
  /deep/.BMapLabel {
    background-color: transparent !important;
    border: 0 !important;
    color: rgb(70, 17, 17) !important;
  }
}
</style>